<script>
import api from '@/services/api';
import { BButton } from 'bootstrap-vue';
import PageTitle from '@/components/PageTitle';
import { avatarApi } from '@/services/avatar/avatar.service';
import pasteImageFileAgent from '@/mixins/pasteImageFileAgent';

export default {
    name: 'AvatarShow',
    mixins: [pasteImageFileAgent],
    components: {
        PageTitle,
        BButton
    },
    data() {
        return {
            avatars: [],
            avatar: null,
            avatar_id: null,
            isOpenModal: false,
            uploadUrl: 'http://dashboard-api.1it.uz/api/uploader/image',
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUpload: [],
            imageUploaded: false
        };
    },

    async mounted() {
        await this.getAvatars();
    },
    methods: {
        async getAvatars() {
            const body = {
                merchant_id: this.$route.params.id
            };
            await avatarApi
                .getAvatars(body)
                .then((response) => {
                    this.avatars = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createAvatar() {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            const formData = new FormData();
            formData.append('merchant_id', this.$route.params.id);
            formData.append('file', this.avatar.file);

            await api.imageUploader
                .imageUpload('avatars', formData, config)
                .then(async () => {
                    this.$toast.success(this.$t('success.added'));
                    this.avatar_id = null;
                    this.avatar = null;
                    this.imageUploaded = true;
                    this.isOpenModal = false;
                    await this.getAvatars();
                })
                .catch((e) => {
                    this.$toast.error(
                        e.response?.data?.message || e.message || this.$t('error.smth')
                    );
                });
        },

        deleteAvatar(id) {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await avatarApi
                        .deleteAvatar(id)
                        .then(() => {})
                        .catch(() => {})
                        .finally(() => {
                            this.getAvatars();
                        });
                }
            });
        },

        onCloseModal() {
            this.avatar_id = null;
            this.avatar = null;
        },

        uploadFiles: function () {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.upload(
                this.uploadUrl,
                this.uploadHeaders,
                this.fileRecordsForUpload
            );
            this.fileRecordsForUpload = [];
        },
        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },
        filesSelected: function (fileRecordsNewlySelected) {
            let validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
        },
        onBeforeDelete: function (fileRecord) {
            let i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                let k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                if (confirm('Are you sure you want to delete?')) {
                    this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                }
            }
        },
        fileDeleted: function (fileRecord) {
            let i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('avatars') }}</template>
            <template #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div>
            <div class="avatars-row">
                <div
                    v-for="avatar in avatars"
                    :key="avatar.id"
                    class="avatar-item"
                    @click="deleteAvatar(avatar.id)"
                >
                    <img :src="avatar.path" alt="" />
                </div>
            </div>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="false"
            :title="$t('add.avatar')"
            cancel-variant="danger"
            centered
            size="lg"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-col cols="12">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('titles.image')"
                        rules="required"
                    >
                        <VueFileAgent
                            ref="vueFileAgent"
                            v-model="avatar"
                            :accept="'image/*,.zip'"
                            :deletable="true"
                            :errorText="{
                                type: $t('vue_file_agent.file_type_error'),
                                size: $t('vue_file_agent.size_type_error')
                            }"
                            :helpText="$t('vue_file_agent.choose_image')"
                            :maxFiles="1"
                            :maxSize="'10MB'"
                            :meta="true"
                            :multiple="false"
                            theme="list"
                            @beforedelete="onBeforeDelete($event)"
                            @delete="fileDeleted($event)"
                            @select="filesSelected($event)"
                        ></VueFileAgent>
                        <span class="validation__red">
                            {{ errors[0] }}
                        </span>
                    </ValidationProvider>
                </b-col>
            </ValidationObserver>

            <template #modal-footer>
                <b-button
                    :disabled="!fileRecordsForUpload.length || imageUploaded"
                    :variant="imageUploaded ? 'success' : 'primary'"
                    @click="createAvatar"
                >
                    {{ $t('button.save') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
.avatars-row {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.avatar-item {
    position: relative;
    width: 100px;
    height: 100px;
    cursor: pointer;
    transition: 0.3s all linear;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: red;
        color: #fff;
        height: 25px;
        border-radius: 0 0 50px 50px;
    }

    &:hover {
        &:after {
            content: 'delete';
            transform: translate(-50%, 0);
        }
    }
}
</style>
