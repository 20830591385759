import Core from '@/services/axios/core.axios';
import { axiosV2 } from '@/services/core/base';

class AvatarService extends Core {
    constructor() {
        super({
            axios: axiosV2({})
        });
    }

    getAvatars(b) {
        return this.get('avatars', {
            params: b
        });
    }

    deleteAvatar(id) {
        return this.delete(`avatars/${id}`);
    }
}

export const avatarApi = new AvatarService();
